import { render, staticRenderFns } from "./moderators-scale.vue?vue&type=template&id=0402ecb4&"
import script from "./moderators-scale.vue?vue&type=script&lang=js&"
export * from "./moderators-scale.vue?vue&type=script&lang=js&"
import style0 from "./moderators-scale.vue?vue&type=style&index=0&id=0402ecb4&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports