<template>
  <b-card>
    <!-- MODAL DE PROFESSORES -->
    <b-modal
      ref="modal-moderators"
      title="Moderadores"
      hide-footer
      scrollable
      no-close-on-backdrop
    >
      <div
        class="d-flex align-items-center pr-50"
        style="font-size: 12px"
        :class="{ 'mt-1': index > 0 }"
        v-for="(asm, index) in showModeratorsModal.appointment_studios_moderator"
        :key="index"
      >
        <b-avatar
          variant="primary"
          badge-variant="danger"
          badge-top
          badge="X"
          badge-offset="-2px"
          button
          @click="deleteModerator(asm.id, showModeratorsModal.item, asm.moderator, true)"
        />
        <div class="ml-75">
          <div>
            <b>{{ asm.moderator.name || "--" }}</b>
          </div>
          <div>{{ asm.moderator.email || "--" }}</div>
        </div>
      </div>
    </b-modal>
    <b-overlay
      :show="isloading"
      spinner-variant="primary"
      spinner-type="grow"
      :variant="skin"
    >
      <Table
        :fields="fields"
        :list="list"
        border="full"
        style="font-size: 10px; min-height: 80vh"
        class="text-nowrap mb-0"
        responsive
      >
        <template #course_event="data">
          <div
            :data-text="data.item.course_event || '--'"
            class="text-truncate ellipsis"
            style="max-width: 150px"
          >
            {{ data.item.course_event || "--" }}
          </div>
        </template>
        <template #studio="data">
          <div
            :data-text="data.item.studio || '--'"
            class="text-truncate ellipsis"
            style="max-width: 150px"
          >
            {{ data.item.studio || "--" }}
          </div>
        </template>
        <template #appointment_id="data">
          <div
            style="color: #0000ff"
            @click="appointmentOpen(data.item.appointment_id)"
          >
            {{ data.item.appointment_id }}
          </div>
        </template>
        <template #status="data">
          <div :class="data.item.statusColor" style="min-width: 150px">
            <v-select
              v-model="data.item.statusSelected"
              :options="status"
              autocomplete="off"
              :clearable="false"
              @input="(status) => updateStatus(data.item, status)"
            />
          </div>
        </template>
        <template #operator_id="data">
          <div
            class="d-flex align-items-center pr-50"
            :class="{ 'mt-1': index > 0 }"
            v-for="(operator, index) in data.item.operators.filter((f) => !f.backup)"
            :key="operator.id"
          >
            <b-avatar variant="primary" />
            <div class="ml-75">
              <div>
                <b>{{ operator.name || "--" }}</b>
              </div>
              <div>{{ operator.email || "--" }}</div>
            </div>
          </div>
          <div class="text-center" v-if="data.item.operators.length == 0">
            --
          </div>
        </template>
        <template #moderator_id="data">
          <div
            class="d-flex align-items-center pr-50"
            :class="{ 'mt-1': index > 0 }"
            v-for="(asm, index) in data.item.appointment_studios_moderator.slice(0, 3)"
            :key="asm.id"
          >
            <b-avatar
              variant="primary"
              badge-variant="danger"
              badge-top
              badge="X"
              badge-offset="-2px"
              button
              @click="deleteModerator(asm.id, data.item, asm.moderator)"
            />
            <div class="ml-75">
              <div>
                <b>{{ asm.moderator.name || "--" }}</b>
              </div>
              <div>{{ asm.moderator.email || "--" }}</div>
            </div>
          </div>

          <div
            style="color: #0000ff"
            class="d-flex justify-content-end mt-25 mr-50"
            v-if="data.item.appointment_studios_moderator.length > 3"
            @click="showModalModerators(data.item)"
          >
            Mostrar mais...
          </div>
          <div class="text-center" v-else-if="data.item.appointment_studios_moderator.length == 0">
            Nenhum moderador associado
          </div>
          <v-select
            v-model="data.item.moderatorSelected"
            class="mt-75"
            :options="moderators"
            autocomplete="off"
            :clearable="true"
            @input="(moderator) => insertModerator(data.item, moderator)"
          />
        </template>
      </Table>
      <div class="d-flex justify-content-center mt-1">
        <b-button @click="getLoadMore" variant="secondary" v-if="more" pill>
          Carregar mais
        </b-button>
      </div>
    </b-overlay>
    <!-- Filter -->
    <b-button
      class="btn-filter-appoitment btn-icon rounded-circle"
      :variant="containsFilter ? 'gradient-warning' : 'gradient-info'"
      @click="isActiveFilter = true"
    >
      <feather-icon icon="FilterIcon" size="25" />
    </b-button>
    <b-sidebar
      sidebar-class="sidebar-lg"
      bg-variant="white"
      v-model="isActiveFilter"
      right
      backdrop
      shadow
      no-header
    >
      <template #default="{ hide }" v-if="isActiveFilter">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Filtros</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <b-form class="px-1 mt-1">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Data">
                <flat-pickr
                  v-model="dataFilter"
                  class="form-control"
                  :config="config"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Empresa">
                <v-select
                  v-if="companies.length > 0"
                  v-model="companieSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="companies"
                  autocomplete="off"
                  multiple
                  :clearable="false"
                >
                  <template v-slot:option="option">
                    <div class="d-flex justify-content-between">
                      <h5 class="mb-0 text-uppercase">
                        {{ option.label }}
                      </h5>
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Estúdio">
                <v-select
                  v-model="studioPageSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="studiosPage"
                  :clearable="true"
                  autocomplete="off"
                  @search="
                    (res) => {
                      filterStudios(res);
                    }
                  "
                  :loading="isLoadingFilterStudio"
                  multiple
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Pesquisando... <em>{{ search }}</em>
                    </template>
                    <em style="opacity: 0.5" v-else>Digite o estúdio</em>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Moderador">
                <v-select
                  v-if="moderators.length > 0"
                  v-model="moderatorsFilterSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="moderators"
                  autocomplete="off"
                  :clearable="false"
                  multiple
                >
                  <template v-slot:option="option">
                    <div class="d-flex justify-content-between">
                      <h5 class="mb-0 text-uppercase">
                        {{ option.label }}
                      </h5>
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Tipo de Agendamento">
                <v-select
                  v-if="jobTypes.length > 0"
                  v-model="jobTypeSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="jobTypes"
                  autocomplete="off"
                  multiple
                  :clearable="false"
                >
                  <template v-slot:option="option">
                    <div class="d-flex justify-content-between">
                      <h5 class="mb-0 text-uppercase">
                        {{ option.label }}
                      </h5>
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Tipo de Transmissão">
                <v-select
                  v-if="transmissionType.length > 0"
                  v-model="transmissionTypeSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="transmissionType"
                  autocomplete="off"
                  multiple
                  :clearable="false"
                >
                  <template v-slot:option="option">
                    <div class="d-flex justify-content-between">
                      <h5 class="mb-0 text-uppercase">
                        {{ option.label }}
                      </h5>
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="9">
              <b-button @click="filter" variant="gradient-info" block>
                FILTRAR
              </b-button>
            </b-col>
            <b-col cols="3">
              <b-button @click="clearfilter" variant="gradient-primary" block>
                Limpar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </template>
    </b-sidebar>
  </b-card>
</template>

<script>
import { VBToggle, BAvatar } from "bootstrap-vue";
import Table from "@/components/Table.vue";
import _appointmentService from "@/services/appointment-service";
import _appointmentStudioService from "@/services/appointment-studio-service";
import _usersService from "@/services/users-service";
import _companyService from "@/services/company-service";
import _studiosGroupService from "@/services/studios-group-service";
import _studioService from "@/services/studios-service";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import useAppConfig from "@core/app-config/useAppConfig";
import _jobTypeService from "@/services/audiovisual/job-type-service";
import _transmissionTypeService from "@/services/audiovisual/transmission-type-service";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    Table,
    BAvatar,
  },
  props: {
    projectId: {
      type: Object,
      default: null,
    },
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isActiveFilter: false,
      more: false,
      isloading: false,
      isLoadingFilterStudio: false,
      currentePage: 1,
      search: "",
      size: 20,
      size_listmoderators: 150,
      fields: [
        { key: "appointment_id", label: "Agendamento" },
        { key: "company", label: "Empresa" },
        { key: "start", label: "Início" },
        { key: "end", label: "Fim" },
        { key: "course_event", label: "Curso/Evento" },
        { key: "studio", label: "Estúdio" },
        { key: "operator_id", label: "Operador" },
        { key: "status", label: "Status" },
        {
          key: "moderator_id",
          label: "Moderadores",
          class: "td-min-column",
        },
      ],
      list: [],
      debounceMilliseconds: 300,
      timeout: null,
      moderators: [],
      companies: [],
      companieSelected: [],
      studios: [],
      studiosPage: [],
      studioSelected: [],
      studioPageSelected: [],
      studiosPageFilterSelected: [],
      moderatorsFilterSelected: [],
      dataFilter: null,
      config: {
        dateFormat: "d-m-Y",
        altFormat: "d/m/Y",
        minDate: "today",
        locale: Portuguese,
      },
      showModeratorsModal: {
        appointment_studios_moderator: [],
        item: null,
      },
      status: [],
      jobTypes: [],
      jobTypeSelected: [],
      transmissionType: [],
      transmissionTypeSelected: [],
    };
  },
  async created() {
    this.initiDateNow();
    let params = this.getParams();
    this.getRecords(this.currentePage, params);
    this.getModerators();
    this.getCompanies();
    this.getStatus();
    this.getJobType();
    this.getTransmissionType();
  },
  computed: {
    containsFilter() {
      var companyid = this.companieSelected
        ? this.companieSelected.map((m) => m.value)
        : ["-1"];

      var studioid = this.studiosPageFilterSelected
        ? this.studiosPageFilterSelected.map((m) => m.value)
        : ["-1"];

      var moderatorid = this.moderatorsFilterSelected
        ? this.moderatorsFilterSelected.map((m) => m.value)
        : ["-1"];

      return (
        this.dataFilter ||
        companyid.some((s) => s !== "-1") ||
        studioid.some((s) => s !== "-1") ||
        moderatorid.some((s) => s !== "-1")
      );
    },
  },
  methods: {
    initiDateNow() {
      const today = new Date();
      this.dataFilter = `${today.getDate()}-${
        today.getMonth() + 1
      }-${today.getFullYear()}`;
    },
    async getModerators() {
      await _usersService
        .showModeratorsAll(this.size_listmoderators)
        .then((res) => {
          if (res.content && res.content.length > 0) {
            this.moderators = this.$utils.populardropdown(
              res.content,
              "name",
              "id",
              false,
              false,
              false
            );
          } else {
            this.$utils.toastError(
              "Notificação",
              "Sem nenhum moderador encontrado."
            );
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    async getRecords(_page, params) {
      this.isloading = true;      
      _appointmentStudioService
        .showScaleModerators(_page, this.size, params)
        .then((res) => {
          if (res.content) {
            this.more = res.content.length >= this.size;
            this.list.push(...res.content.map(m => {
              var status = m.status_id ? this.status.find(f => f.value == m.status_id) : null;
              return {
                ...m,
                statusSelected: status,
                statusColor: this.getStatusColor(status)
              }
            }));
            this.currentePage = _page;
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    getLoadMore(_page) {
      let params = this.getParams();
      this.getRecords(this.currentePage + 1, params);
    },
    getParams() {
      let params = {};
      if (this.dataFilter) {
        let dates = this.dataFilter.split("-");
        params.date = `${dates[2]}-${dates[1]}-${dates[0]}`;
      }
      if (this.companieSelected && this.companieSelected.length > 0) {
        params.company_id = [];
        this.companieSelected.forEach((element) => {
          params.company_id.push(element.value);
        });
      }

      if (this.studioPageSelected && this.studioPageSelected.length > 0) {
        params.studio_id = [];
        this.studioPageSelected.forEach((element) => {
          params.studio_id.push(element.value);
        });
      }

      if (
        this.moderatorsFilterSelected &&
        this.moderatorsFilterSelected.length > 0
      ) {
        params.moderator_id = [];
        this.moderatorsFilterSelected.forEach((element) => {
          params.moderator_id.push(element.value);
        });
      }

      if (
        this.jobTypeSelected &&
        this.jobTypeSelected.length > 0
      ) {
        params.job_type_id = [];
        this.jobTypeSelected.forEach((element) => {
          params.job_type_id.push(element.value);
        });
      }

      if (
        this.transmissionTypeSelected &&
        this.transmissionTypeSelected.length > 0
      ) {
        params.transmission_type_id = [];
        this.transmissionTypeSelected.forEach((element) => {
          params.transmission_type_id.push(element.value);
        });
      }

      return params;
    },
    filter() {
      this.currentePage = 1;
      this.list = [];
      let params = this.getParams();
      this.getRecords(this.currentePage, params);
    },
    insertModerator(item, moderator) {
      if (!(item, moderator)) return;
      _appointmentStudioService
        .insertModerator({
          data: {
            moderator_id: moderator.value,
            appointment_studio_id: item.appointment_studio_id,
          },
        })
        .then((response) => {
          item.appointment_studios_moderator.unshift({
            id: response.content.appointment_studios_moderator_id,
            moderator: {
              id: response.content.moderator.id,
              name: response.content.moderator.name,
              email: response.content.moderator.email,
            }
          });
          this.$utils.toast("Sucesso!", "Moderador vinculado com sucesso");
        })
        .catch((error) => {
          this.$utils.toastError("Notificação", error);
        });
    },
    updateStatus(item, status) {
      if (!(item, status)) return;
      item.statusColor = this.getStatusColor(status);
      _appointmentStudioService
        .updateStatus({
          data: {
            appointment_studio_id: item.appointment_studio_id,
            status_id: status.value
          }
        })
        .then((_res) => {
          this.$utils.toast("Sucesso!", "Status atualizado com sucesso");
        })
        .catch((error) => {
          this.$utils.toastError("Notificação", error);
        })
    },
    deleteModerator(id, item, moderator, isModal = false) {
      if (!(id, item, moderator)) return;
      this.$swal({
        title: "Tem certeza?",
        text: `Deseja realmente remover o moderador \"${moderator.name}\" desta demanda?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, remover!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          _appointmentStudioService
            .deleteModerator(id)
            .then((_response) => {
              item.appointment_studios_moderator = item.appointment_studios_moderator.filter((m) => m.moderator.id != moderator.id);
              if (isModal) this.showModeratorsModal.appointment_studios_moderator = this.showModeratorsModal.appointment_studios_moderator.filter((m) => m.moderator.id != moderator.id);
              this.$utils.toast("Sucesso!", "Moderador removido da demanda com sucesso");
            })
            .catch((error) => {
              this.$utils.toastError("Notificação", error);
            });
        }
      });
    },
    async getCompanies() {
      await _companyService
        .showAll()
        .then((res) => {
          if (res.content && res.content.length > 0) {
            this.companies = this.$utils.populardropdown(
              res.content,
              "name",
              "id",
              false,
              false,
              false
            );
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    async getStatus() {
      await _appointmentStudioService
        .getStatusList()
        .then((res) => {
          if (res.content && res.content.length > 0) {
            this.status = res.content.map(m => {
              return {
                value: m.id,
                label: m.name
              }
            })
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    filterStudios(_search) {
      if (_search && _search.length >= 3) {
        if (!this.isLoadingFilterStudio) {
          this.isLoadingFilterStudio = true;
          setTimeout(() => {
            _studioService
              .findByName(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.studiosPage = [];
                  res.content.forEach((element) => {
                    this.studiosPage.push({
                      value: element.id,
                      label: element.name,
                    });
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.isLoadingFilterStudio = false));
          }, 1500);
        }
      }
    },
    clearfilter() {
      this.initiDateNow();
      this.companieSelected = null;
      this.studioPageSelected = null;
      this.moderatorsFilterSelected = null;
      this.jobTypeSelected = null;
      this.transmissionTypeSelected = null;
      this.filter();
    },
    appointmentOpen(id) {
      if (this.$utils.isPermited("permission.appointment.link.view")) {
        this.$router.push({
          path: `/pedagogic/calendar?q=${id}`,
        });
      }
    },
    showModalModerators(item) {
      this.showModeratorsModal.appointment_studios_moderator = [...item.appointment_studios_moderator];
      this.showModeratorsModal.appointment_studios_moderator.sort(function (a, b) {
        return a.moderator.name < b.moderator.name ? -1 : a.moderator.name > b.moderator.name ? 1 : 0;
      });
      this.showModeratorsModal.item = item;
      this.$refs["modal-moderators"].show();
    },
    getStatusColor(status) {
      if(status && status.value) {
        switch (status.value) {
          case 1:
            return "canceled"
          case 4:
            return "concluded"
        }
      }
    },
    async getJobType() {
      await _jobTypeService
        .getSimplifiedJobTypes()
        .then((res) => {
          if (res.content && res.content.length > 0) {
            this.jobTypes = this.$utils.populardropdown(
              res.content,
              "name",
              "id",
              false,
              false,
              false
            );
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
    },
    async getTransmissionType() {
      await _transmissionTypeService
        .showProductsAll()
        .then((res) => {
          if (res.content && res.content.length > 0) {
            this.transmissionType = this.$utils.populardropdown(
              res.content,
              "name",
              "id",
              false,
              false,
              false
            );
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
    },
  },
};
</script>
<style>
.canceled .v-select.vs--single .vs__selected {
  color: red !important;
}
.concluded .v-select.vs--single .vs__selected {
  color: green !important;
}
.td-min-column {
  min-width: 200px;
}
.btn-filter-appoitment {
  position: fixed;
  bottom: 5%;
  right: 45px;
  z-index: 99;
}
.ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s linear;
  max-width: 250px;
  padding: 0.5rem 0rem;
}
.ellipsis:focus,
.ellipsis:hover {
  color: transparent;
}
.ellipsis:focus:after,
.ellipsis:hover:after {
  content: attr(data-text);
  overflow: visible;
  text-overflow: inherit;
  background: #fff;
  position: absolute;
  left: auto;
  top: auto;
  width: auto;
  border: 1px solid #eaebec;
  padding: 0 0.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  white-space: nowrap;
  word-wrap: break-word;
  display: block;
  color: black;
  margin-top: -1.25rem;
}
</style>
